import React from "react";

import * as Scroll from 'react-scroll';
import { ApartmentGridCell } from "./ApartmentGridCell";

import './Apartments.css'

let Element = Scroll.Element;

interface ApartmentProps {
    onApartmentClick: (nr: string) => void
}

export default function Apartments(props: ApartmentProps) {

    return (
        <Element name="apartmentsElement" className="Element">
            <section className={`apartmentsSection`} >
                <h2>APARTMENTS</h2>
                <div className="apartmentsContainer">
                    <h4>One bedroom apartment: Sleeps up to four (4)</h4>
                    <section className="apartmentsRow">
                        <ApartmentGridCell onApartmentClick={() => props.onApartmentClick("one-bedroom-apartment")} idx={0} img_idx={0}/>
                        <ApartmentGridCell onApartmentClick={() => props.onApartmentClick("one-bedroom-apartment")} idx={0} img_idx={1}/>
                        <ApartmentGridCell onApartmentClick={() => props.onApartmentClick("one-bedroom-apartment")} idx={0} img_idx={6}/>
                    </section>
                    <div className={"apartmentInfo"}>
                        <h5 onClick={() => props.onApartmentClick("one-bedroom-apartment")}>One bedroom apartments</h5>
                    </div>
                    <h4>Two bedroom apartment: Sleeps up to six (6)</h4>
                    <section className="apartmentsRow">
                        <ApartmentGridCell onApartmentClick={() => props.onApartmentClick("two-bedroom-apartment")} idx={1} img_idx={2}/>
                        <ApartmentGridCell onApartmentClick={() => props.onApartmentClick("two-bedroom-apartment")} idx={1} img_idx={5}/>
                        <ApartmentGridCell onApartmentClick={() => props.onApartmentClick("two-bedroom-apartment")} idx={1} img_idx={8}/>
                    </section>
                    <div className={"apartmentInfo"}>
                        <h5 onClick={() => props.onApartmentClick("two-bedroom-apartment")}>Two bedroom apartments</h5>
                    </div>
                    <h4>Apartment with Terrace: Sleeps up to four (4)</h4>
                    <section className="apartmentsRow">
                        <ApartmentGridCell onApartmentClick={() => props.onApartmentClick("terrace-apartment")} idx={2} img_idx={0}/>
                        <ApartmentGridCell onApartmentClick={() => props.onApartmentClick("terrace-apartment")} idx={2} img_idx={1}/>
                        <ApartmentGridCell onApartmentClick={() => props.onApartmentClick("terrace-apartment")} idx={2} img_idx={9}/>
                    </section>  
                    <div className={"apartmentInfo"}>
                        <h5 onClick={() => props.onApartmentClick("terrace-apartment")}>Terrace apartments</h5>
                    </div>
                </div>
            </section>
        </Element >
    );
}