import React from 'react';
import './App.css';

import HomePage from "./home/home";
import {Route, Switch, useHistory} from "react-router-dom";
import ClarisHeader from "./common/ClarisHeader";
import ApartmentPage from "./apartment/ApartmentPage";
import TermsPage from './terms/en/TermsPage';
import LegalPage from './legal-notice/en/LegalPage';
import PrivacyPage from './privacy-policy/en/PrivacyPage';
import ES_LegalPage from './legal-notice/es/LegalPage';
import FR_LegalPage from './legal-notice/fr/LegalPage';
import ES_PrivacyPage from './privacy-policy/es/PrivacyPage';
import FR_PrivacyPage from './privacy-policy/fr/PrivacyPage';
import ES_TermsPage from './terms/es/TermsPage';
import FR_TermsPage from './terms/fr/TermsPage';
import {Book} from "./book/Book";
import { FirebaseApp } from 'firebase/app';

interface AppProps {
    firebase_app: FirebaseApp;
}

function App(props: AppProps) {
    const {push} = useHistory();

    const onClickApartment = (apartmentNumber:string) => {
        push(`/apartment/${apartmentNumber}`);
    };


    return (
    <div className="App">
        <ClarisHeader root={"/"} app={props.firebase_app} />

        <Switch>
            <Route exact path="/">
                <HomePage onClickApartments={(n) => onClickApartment(n)}/>
            </Route>
            <Route path="/apartment/:nr">
                <ApartmentPage />
            </Route>
            <Route path="/en-us/terms-of-use">
                <TermsPage/>
            </Route>
            <Route path="/es-es/terms-of-use">
                <ES_TermsPage/>
            </Route>
            <Route path="/fr-fr/terms-of-use">
                <FR_TermsPage/>
            </Route>
            <Route path="/en-us/legal-notice">
                <LegalPage/>
            </Route>
            <Route path="/es-es/legal-notice">
                <ES_LegalPage/>
            </Route>
            <Route path="/fr-fr/legal-notice">
                <FR_LegalPage/>
            </Route>
            <Route path="/en-us/privacy-policy">
                <PrivacyPage/>
            </Route>
            <Route path="/es-es/privacy-policy">
                <ES_PrivacyPage/>
            </Route>
            <Route path="/fr-fr/privacy-policy">
                <FR_PrivacyPage/>
            </Route>
        </Switch>

    </div>
  );
}

export default App;
