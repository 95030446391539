
import React from "react";

import {Map, Marker, GoogleApiWrapper} from 'google-maps-react';


const mapStyles = {
    width: '100%',
    height: '100%',
    position: 'absolute'
};
const coords = {lat: 41.3959284, lng: 2.1637328};

export class MapContainer extends React.Component {
    state = {
        showingInfoWindow: false,  //Hides or the shows the infoWindow
        activeMarker: {},          //Shows the active marker upon click
        selectedPlace: {}          //Shows the infoWindow to the selected place upon a marker
    };

    onMarkerClick = (props, marker, e) => {
        this.setState({
            selectedPlace: props,
            activeMarker: marker,
            showingInfoWindow: true
        });
    };

    onClose = props => {
        if (this.state.showingInfoWindow) {
            this.setState({
                showingInfoWindow: false,
                activeMarker: null
            });
        }
    };

    render() {
        return (
            <Map google={this.props.google} zoom={16} style={mapStyles} initialCenter={coords}>

                <Marker
                    name={'Claris Apartments'}
                    title={'Claris Apartments'}
                    position={{lat: 41.3958558, lng: 2.1632998}}
                    onClick={(props, marker, e) => this.props.onMarkerClick(!this.showingInfoWindow)}>
                </Marker>
            </Map>
        );
    }
}

export default GoogleApiWrapper({
    apiKey: ('AIzaSyDSy32bcYu0v-ufbYm5rZ9XMlk-NPyhnDQ')
})(MapContainer)