import React, {useState} from "react";


import './Location.css';
import ClarisMaps from "./ClarisMaps";


import * as Scroll from 'react-scroll';
import { Icon } from "semantic-ui-react";
let Element = Scroll.Element;


export default function Location(){
    let [markerState, setMarkerState] = useState(false)

    return (
        <Element name="locationElement">
            <section className="locationSection">

                <h2>LOCATION</h2>
                <div className="mapContainer">
                    <div className="markerInfoBox">{markerState ? <div>
                        <h5>Claris Apartments</h5>
                        <p>Carrer de Pau Claris, 178</p>
                        <p>Barcelona, Spanien</p>
                        <p className="phoneSpacing"><a className="phone" href="tel:+34-933-081-164">(+34) 933 081 164</a></p>
                        <h6><a href="https://www.google.dk/maps/place/Claris+Apartments/@41.3958421,2.1612033,17z/data=!3m1!4b1!4m8!3m7!1s0x12a4a2f1c7288e17:0xce6e2ee06305f9d2!5m2!4m1!1i2!8m2!3d41.3958381!4d2.163392">Get directions</a></h6>
                        <button className="close" onClick={(e)=> setMarkerState(false)}>X</button>
                    </div> : <span></span>}</div>
                    <div className="map">
                         <ClarisMaps onMarkerClick={(markerState: boolean) => {setMarkerState(markerState)}}/>
                    </div>

                </div>

                <h4>HOW TO GET HERE</h4>
                <main className="transportationInfoContainer">

                    <div className="transportOption">
                        <h5>BY CAR <Icon name="car" className={"transport-icon"} /></h5>
                        <p>Our address is Pau Claris 178, 08037 Barcelona. Please call us at +(34) 933 081 164 or write us at info@clarisapartments.com for any assistance.
                        </p>
                        <h5>BY TRAIN<Icon name="train" className={"transport-icon"} /></h5>
                        <p>You may be arriving by train to Barcelona Sants. A taxi will cost around €15. If you’d prefer to take the metro, you should take Line 5 (blue) towards "Vall d´Hebron,” get off at Diagonal. At the exit walk towards La Pedrera building in Passeig de Gracia, then turn left into Provença street and walk up to next crossing with Pau Claris. Our apartments are in the corner.
                        </p>
                    </div>
                    <div className="transportOption">
                        <h5>FROM THE AIRPORT<Icon name="plane" className={"transport-icon"} /></h5>
                        <p>Barcelona - El Prat Airport is 15 km away. Below best options to reach the apartments:
                        </p>
                        <p><Icon name="taxi" className={"transport-inline-icon"} /><b>By taxi:</b> this is the easiest and fastest way. It will take less than 30 minutes and the cost is approximately €35.</p>
                        <p><Icon name="train" className={"transport-inline-icon"} /><b>By train:</b> Trains depart from T2. Depends on which terminal you arrive, there are frequent free bus shuttles that connect all terminals. Trains depart every 30 minutes and stop in different area making its way to Barcelona city center. Stop at Passeig de Gracia – Arago. From there walk up Pau Claris street until you reach 178 number. This is around 8 minutes away. It costs 4.60 euros</p>
                        <p><Icon name="train" className={"transport-inline-icon"} /><b>By metro:</b> Take the underground from airport terminal 1 or 2 to Zona Universitaria Station L9 (orange line) and there transfer to L3 (green line) until Diagonal Station. Underground departs every 5-7 minutes and it will take around 50 minutes. From diagonal metro station you will exit Passeig de Gracia, there walk towards La Pedrera and then turn left into Provenza Street. We are just at next corner. This is around 5 minutes walk. This costs 5.15 euro per person per trip. T-casual card is not valid for the metro airport trip.</p>
                        <p><Icon name="bus" className={"transport-inline-icon"} /><b>By bus:</b> The most comfortable (around 40 minutes): take the Aerobus service, which makes several stops on its way to the center of Barcelona. It costs €5.90 each way. If you purchase a round-trip ticket the price is €10.20. We recommend you take a look at the terms and conditions of service on their website.
                        </p>
                    </div>
                </main>
            </section>
        </Element>
    );
};