import React from 'react';

import ClarisFooter from "../../common/ClarisFooter";

export default function FR_TermsPage() {

    return (
        <main className={"homeContainer"}>
            <div style={{ width: "80%", margin: "0 auto" }}>
                <h2 style={{ marginTop: "20px", marginBottom: "40px" }}>CONDITIONS GÉNÉRALES</h2>
                <h4>RÉSERVATIONS:</h4>
                <p>Une fois que le client a effectué la réservation via notre site Web, en saisissant les données
personnelles et les données de la carte de crédit, il recevra un e-mail avec la confirmation de
sa réservation.</p>

                <h4>PAIEMENT DE RÉSERVATION:</h4>
                <p>Le paiement de la réservation se fera à l&#39;arrivée à l&#39;appartement par carte bancaire (Visa ou
Mastercard), sauf pour les offres qui ont leurs propres conditions :</p>
                <p>- Dans les tarifs non remboursables, le paiement de 100 % sera effectué au moment de la
réservation.</p>

                <h4>PRÉAUTORISATION:</h4>
                <p>Dans les réservations avec des conditions d&#39;annulation gratuite jusqu&#39;à 20 jours ou 5 jours,
nous effectuerons une pré-autorisation de 250 euros sur la carte de crédit fournie au moment
de la réservation pour garantir sa validité. Ce montant constituera l&#39;acompte qui sera annulé
au moment du départ.</p>

                <h4>MODIFICATION DE DATES DE SEJOUR:</h4>
                <p>Si le client souhaite modifier les dates de sa réservation, il doit informer Claris Apartments par
e-mail. En cas de disponibilité à d&#39;autres dates, la modification sera effectuée sans frais
supplémentaires. S&#39;il n&#39;y a pas de disponibilité aux nouvelles dates choisies par le client et que
le client décide d&#39;annuler la réservation, les conditions d&#39;annulation de la réservation seront
appliquées.</p>

                <p>Dans le cas de l&#39;offre non remboursable, vous devez contacter Claris Apartments pour évaluer
la possibilité de modification.</p>

                <h4>ANNULATION DE RÉSERVATION</h4>
                <p>Le client pourra annuler sa réservation sans frais selon le type de tarif et conditions qu&#39;il aura
réservé :</p>
                <ul>
                    <li>Non remboursable : le montant est non remboursable en cas d&#39;annulation</li>
                    <li>Tarif standard : le client peut annuler sans frais jusqu&#39;à 20 jours avant l&#39;arrivée. Sinon, le
client paiera 100% de la réservation.</li>
                    <li>Tarif flexible : le client peut annuler sans frais jusqu&#39;à 5 jours avant l&#39;arrivée, en cas
d&#39;annulations ultérieures, 100% de la réservation doit être payée.</li>
                </ul>
                <p>Claris Apartments se réserve le droit, en cas de force majeure (incendie, inondation, vol, ...) de
changer l&#39;appartement réservé par le client pour un autre de même catégorie ou de catégorie
supérieure.</p>

                <h4>IDENTITÉ DU CLIENT :</h4>
                <p>Le client doit informer Claris Apartments du nombre total de personnes qui occupera
l&#39;appartement, ainsi que leurs noms, prénoms, date de naissance et pièce d&#39;identité. Seules les
personnes inscrites peuvent l&#39;utiliser en fonction de la capacité maximale de chaque
appartement. Le client qui effectue la réservation doit fournir son nom, son numéro de
téléphone et son e-mail lors de la réservation.</p>

                <h4>POLITIQUE ENFANTS:</h4>
                <p>Les enfants de moins de 2 ans séjournent gratuitement.</p>

                <h4>POLITIQUE POUR LES ANIMAUX :</h4>
                <p>Les animaux domestiques ne sont pas admis dans les appartements.</p>


                <h4>CAUTION:</h4>
                <p>Pour toute réservation, une caution de 250 EUR par appartement doit être versée pour couvrir
d&#39;éventuels dommages. Cette caution sera remboursée une fois l&#39;état de l&#39;appartement vérifié.</p>

                <h4>GROUPES:</h4>
                <p>Les réservations de 2 appartements ou plus seront considérées comme un groupe. Ce type de
réservation peut entraîner des conditions différentes et des suppléments, le service des
réservations vous contactera lors de la réservation pour vous informer des conditions
particulières.</p>
                <p>Les groupes d&#39;enterrements de vie de garçon ou similaires ne sont pas acceptés.</p>

                <h4>ENREGISTREMENT/DÉPART:</h4>
                <p>Une fois votre réservation effectuée et quelques jours avant votre arrivée, notre équipe vous
contactera pour programmer votre arrivée et répondre à toutes vos questions. Il est très
important qu&#39;une heure avant votre arrivée dans les appartements, vous nous recontactiez par

e-mail, message ou téléphone. De cette façon, nous garantissons d&#39;éliminer tout type d&#39;attente
due à des retards ou à des changements de plans de dernière minute.</p>
                <p>Horaires de bureau : du lundi au vendredi de 09h30 à 13h30 et de 15h00 à 19h00. Situé
dans le même bâtiment que les appartements.</p>
                <p>Check-in directement à votre appartement à partir de 15h00. En cas d&#39;arrivée plus tôt,
nous essaierons de donner accès à l&#39;appartement dès que possible en fonction des
disponibilités.</p>
                <p>Enregistrement tardif. Après 20h30, un supplément de 30 euros sera facturé, qui sera
payé à l&#39;arrivée. Pour les arrivées après 00h00, la charge sera de 50 euros.</p>
                <p>Départ : se fera avant 12h00. Laissez les clés sur la table de votre appartement.
Éteignez les lumières et la climatisation et fermez la porte.</p>
                <p>Consigne bagages gratuite : disponible du lundi au vendredi pendant les heures de
bureau.</p>

                <h4>ÉQUIPEMENT / FOURNITURES / NETTOYAGE :</h4>
                <p>Toutes les fournitures d&#39;eau et d&#39;électricité sont incluses dans le prix de l&#39;appartement. Les
appartements sont entièrement équipés : cuisine, draps, serviettes et kit de nettoyage.</p>

                <h4>TAXES: </h4>
                <p>Les réservations sont soumises à un supplément de TVA de 10%, déjà inclus dans le prix.</p>
                <p>Taxe de séjour : les réservations auront un supplément de 4,40 euros par personne et par nuit
qui devra être réglé à l&#39;arrivée.</p>

                <h4>RESPONSABILITÉS:</h4>
                <p>Claris Apartments n&#39;est pas responsable des conséquences pouvant découler de l&#39;utilisation
faite par le client pendant son séjour dans l&#39;appartement, y compris les dommages, blessures,
pertes dues à un vol, un incendie ou une activité criminelle. Dans le cas où, pendant le séjour
du client dans l&#39;appartement, il y aurait une panne non liée à une mauvaise utilisation par le
client et rendant son séjour impossible, Claris Apartments se chargera de la réparer dans les
plus brefs délais, après en avoir informé le client. Le service de maintenance est opérationnel
de 09h30 à 19h00.</p>

                <h4>COMPORTEMENT DE LES HÔTES:</h4>
                <p>Le client et ses accompagnateurs doivent se comporter de manière civique, en
respectant les règles de coexistence. Il est important de respecter le repos des autres
clients, donc aucun bruit ne peut être fait entre 22h00 et 8h00. En cas de ne pas
respecter des règles générales de coexistence, déranger les voicins ou d&#39;intervention de
la police, Claris Apartments aura le droit d&#39;exiger l&#39;expulsion de l&#39;appartement sans
aucune indemnité.</p>
                <p>Il est totalement interdit de fumer à l&#39;intérieur des appartements. Ne respecter pas le
norme entraîne un supplément de 150 euros.</p>
                <p>Vous devez laisser l&#39;appartement dans les mêmes conditions de propreté et d&#39;ordre que
vous avez trouvées à l&#39;entrée. Dès le moment de votre départ, nous examinerons
l&#39;appartement dans les 24 heures. En cas de dommages importants, de saleté/déchets
ou de quelque chose manquant, nous sommes autorisés à débiter votre carte de crédit
en conséquence.</p>
                <p>Le concierge est autorisé à entrer dans l&#39;appartement en cas d&#39;urgence ou de
problèmes techniques. Si nécessaire, le concierge entrera sans notification préalable au
client.</p>  
                <p>En cas d&#39;oubli de la clé à l&#39;intérieur de l&#39;appartement pendant votre séjour ou
d&#39;endommagement de la serrure par suite d’une mauvaise utilisation, le client devra
s&#39;acquitter des frais correspondants. En cas de perte, ou de non remise de la clé au
départ, le client devra régler 60 euros par jeu de clés, qui pourront être débités sur la
carte bancaire fournie en garantie.</p>
                <p>Ni notre société ni la centrale de réservation auprès de laquelle vous avez effectué
votre réservation ne pourront être tenues responsables des dommages directs ou
indirects causés par le client lors de son séjour dans les appartements. Toute plainte
que vous pourriez avoir concernant l&#39;appartement doit être faite pendant votre séjour.
Nous n&#39;acceptons aucune réclamation en dehors de cette période.</p>

                <h4>INSCRIPTION À L&#39;ACTIVITÉ TOURISTIQUE DES APPARTEMENTS CLARIS:</h4>
                <p>Licences touristiques</p>
                <ul>
                <li>HUTB00266359</li>
                <li>HUTB00266454</li>
                <li>HUTB00755978</li>
                <li>HUTB00266252</li>
                </ul>
            </div>
            <ClarisFooter />
        </main >
    );
}