import React from 'react';

import ClarisFooter from "../../common/ClarisFooter";

export default function TermsPage() {

    return (
        <main className={"homeContainer"}>
            <div style={{ width: "80%", margin: "0 auto" }}>
                <h2 style={{ marginTop: "20px", marginBottom: "40px" }}>TERMS OF USE</h2>
                <h4>RESERVATIONS:</h4>
                <p>Once you reserve through our website by entering your personal data and credit card details, you will receive your email confirmation of your reservation.</p>

                <h4>WAY OF PAYMENT:</h4>
                <p>The payment of the invoice is due upon arrival at the apartment by credit card (Visa or Mastercard), except for offers that have their own terms:</p>
                <p>- Non-refundable: payment will be 100% at the time of booking.</p>

                <h4>PREAUTHORIZATION:</h4>
                <p>In reservations with free cancellation conditions up to 20 or 5 days prior to arrival, Silmar Apartments S.L. will preauthorize your credit card with 250 euros at the time of confirmation in order to validate your booking. This will be your deposit that will be cancelled upon departure.</p>

                <h4>RESERVATIONS AMENDMENTS:</h4>
                <p>If the client wishes to change the dates of their reservation, you will be required to contact Claris Apartments by email. If there is availability for the chosen dates, modifications will be made at no extra cost. If there is not availability in the new dates chosen by the client and the client wishes to cancel the reservation, cancellation policies of this reservation will be applied.</p>

                <p>For the Non-refundable reservations, you may contact Claris Apartments to evaluate the possible modification.</p>

                <h4>CANCELLATIONS:</h4>
                <p>You may cancel free of charge at no extra costs depending on the rate and conditions of your reservation:</p>
                <ul>
                    <li>Non-refundable: the amount is non-refundable in case of cancellation</li>
                    <li>Standard rate: you may cancel free of charge up to 20 days prior to arrival. Later cancellations will have 100% charges.</li>
                    <li>Flexible rate: you may cancel free of charge up to 5 days prior to arrival. Less than this period, there will be 100% penalty.</li>
                </ul>
                <p>In case of any major force (fire, flooding, theft) Claris Apartments reserve the right to change the apartment reserved by the client for one of the same or a superior category.</p>

                <h4>GUEST IDENTITY:</h4>
                <p>The client must inform Claris Apartments of the total number of people who will be occupying the apartment during their stay, as well as full name, date of birth and personal identification (ID or passport). Only registered people can make use of the apartment. The client that confirms reservation must provide full name, contact telephone number, and email address at the time of booking.</p>

                <h4>CHILD POLICY:</h4>
                <p>Children under 2 years old will be free of charge</p>

                <h4>PETS POLICY:</h4>
                <p>Pets are not allowed.</p>


                <h4>DAMAGE DEPOSIT:</h4>
                <p>For all bookings a deposit of 250 EUR per apartment must be paid to cover any damage. This deposit will be refunded once the condition of the apartment has been checked.</p>

                

                <h4>GROUPS:</h4>
                <p>Reservations of 2 or more apartments will be considered as a group. This type of reservation may involve different conditions and additional supplements. The reservations department will contact you when the reservation is made to inform you of the special conditions.</p>
                <p>Hen/stag parties or similar are not allowed.</p>

                <h4>CHECK-IN/ CHECK-OUT:</h4>
                <p>Once your booking has been confirmed and a few days prior to arrival, someone of our team will get in touch with you to schedule your arrival and assist you with any information you may require. It is very important that 1 hour prior to your arrival you get in touch with us by email, phone, or message to reconfirm your arrival time. This is to avoid any waiting time due to any last-minute contingencies.</p>
                <p>Office hours: Monday to Friday from 09:30 to 13:30 and from 15:00 to 19:00 hours. This is located on the ground floor of the building.</p>
                <p>Check-in directly in your apartment from 15:00 hours. Should you arrive earlier, we will do our best to provide you access depending on availability.</p>
                <p>Late check in. From 20:00 hours there will be an extra charge of 30 euros paid on arrival. For any arrivals from 00:00 hours, the extra charge will be 50 euros.</p>
                <p>Check out will be required before 12:00 hours. You may leave your keys on top of the table, switch any electronic devices off and close the door.</p>
                <p>Luggage: you are welcome to leave your luggage in our office (office time) free of charge.</p>

                <h4>EQUIPMENTS / SUPPLIES / CLEANING:</h4>
                <p>All electricity, water and gas supplies are included in the apartment rental price. Our apartments are fully equipped: kitchen, bedlinen, towels and cleaning set. </p>

                <h4>TAXES:</h4>
                <p>According to Spanish law, all reservations have a 10% value added tax, already included in the total invoice of the reservation. </p>
                <p>City Tax: reservations will have a charge of € 4,40 per person per night payable on arrival.</p>

                <h4>RESPONSABILITIES:</h4>
                <p>Claris Apartments will not be held liable for any consequences which could occur from the clients use of the apartment during their stay, this includes physical damages, loss by theft, fire or criminal behaviour. If by any chance there should be a damage or fault in the apartment not caused by the wrong usage of the client and it was impossible for the client to stay in the apartment, Claris Apartments would take responsibility for the repair of it as soon as possible, with prior notice from the client. Maintenance service is available from 09:30 to 19:00 hours.</p>

                <h4>GUEST BEHAVIOUR:</h4>
                <p>The client and his/her guests have a duty to behave in a civic manner and to respect the normal co-existence rules. No noise can be made between 22:00 hours to 08:00 hours to respect the rest of other guests. In case that these general co-existence rules are not kept, Claris Apartments have the right to demand the guests to vacate the apartment with no compensation.</p>
                <p>Smoking is totally forbidden in the apartments. Non-compliance with this rule carries a charge of 150 euros.</p>
                <p>The apartment must be left in the same condition as at the start of the agreement. From the moment you leave the apartment within 24 hours, we will check if the conditions of the apartment have remained the same. If there is any damage or loss, we are authorized by your signature above to charge the necessary amount from your credit card or deduct from deposit.</p>
                <p>The concierge to enter the apartment in case of emergency or technical problems. If necessary, concierge would enter without prior notice to the client.</p>  
                <p>To pay any possible charge in an emergency locksmith, if you lock yourselves out, or making the lock unusable by leaving the key in the lock inside the apartment. To pay 60 euros per set of keys. In case of losing the key or do not leave them at the apartment on your departure.</p>
                <p>Neither OUR COMPANY nor the central Reservation Company shall be liable for any direct or indirect damages that may arise because of the use by the client of the apartment, including without limitation, damages, insurance, losses because of fires, robbery or criminal behavior. Complaints about the apartment must be done during your stay. We will not accept any claim after this period.</p>

                <h4>CLARIS APARTMENTS TOURIST ACTIVITY REGISTRATION:</h4>
                <p>TOURIST LICENCES</p>
                <ul>
                <li>HUTB00266359</li>
                <li>HUTB00266454</li>
                <li>HUTB00755978</li>
                <li>HUTB00266252</li>
                </ul>
            </div>
            <ClarisFooter />
        </main >
    );
}