import React from 'react';

import * as Scroll from 'react-scroll';

import './ClarisHeader.css'

import { Button } from 'semantic-ui-react'
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { getAnalytics, logEvent } from 'firebase/analytics';
import { FirebaseApp } from 'firebase/app';

let ScrollLink = Scroll.Link;

interface HeaderProps {
    root: string;
    app: FirebaseApp;
}

function ClarisHeader(props: HeaderProps) {
    const location = useLocation();
    const analytics = getAnalytics(props.app);

    return (
        <header className="navBar">
            <h1><Link to={props.root}>CLARIS APARTMENTS BARCELONA</Link></h1>
                <ul>
            {location.pathname !== "/privacy-policy" && location.pathname !== "/terms-of-use" && location.pathname !== "/legal-notice" &&

                <>
                    <li><ScrollLink activeClass="active" to="apartmentsElement" spy={true} offset={-110} duration={500}>
                        APARTMENTS
                </ScrollLink></li>
                    <li><ScrollLink activeClass="active" to="locationElement" spy={true} offset={-110} duration={500}>
                        LOCATION
                </ScrollLink></li>
                    <li><ScrollLink activeClass="active" to="aboutElement" spy={true} offset={-110} duration={500}>
                        FEATURES
                </ScrollLink></li>
                </>
            }
                    <li><Button href="https://clarisapartments.kross.travel" color='orange' className={"my-orange"}>BOOK NOW</Button></li>
                </ul>
        </header>
    );
}

//<li><Button href="https://claris-apartments.amenitiz.io/en/booking/room" color='orange' className={"my-orange"} onClick={() => logEvent(analytics, 'notification_received')}>BOOK NOW</Button></li>
export default ClarisHeader;