import React, { useEffect, useState } from 'react';
import { Item, Label, Icon, Segment } from "semantic-ui-react";
import './Reviews.css'

interface Review {
    author: string,
    date: string,
    title: string,
    text: string,
    stars: number,
    origin: string,
}

export default function Reviews() {

    const reviews: Review[] = [
        { author: "Hwee", date: "December 11, 2022", title: "Exceptional\n", text: "love this apartment that it offers", stars: 10, origin: "booking.com" },
        { author: "Michael", date: "December 7, 2022", title: "Exceptional\n", text: "Spacious apartment in great location", stars: 10, origin: "booking.com" },
        { author: "Anca", date: "November 20, 2022", title: "Wonderful Stay\n", text: "The location was amazing near the Pedrera Milla House and the best shops! The host was amazing too, and we got the best directions", stars: 10, origin: "booking.com" },
        { author: "Vanessa", date: "October 2, 2022", title: "The pictures are the reality..", text: "The pictures are the reality and we liked everything. The apartment is very cozy and has all the comforts. The people who welcomed us were amazing and very attentive. The location is excellent!! ", stars: 5, origin: "google.com" },
        { author: "Nathalie", date: "October 1, 2022", title: "Super location..", text: "Super location, next to La Pedrera, very clean, would definitely rcome back and have no hesitation in recommending", stars: 5, origin: "google.com" },
        { author: "Kerenohr", date: "September 7, 2022", title: "Unique\n", text: "Great location, new and very comfertable", stars: 10, origin: "booking.com" },
        { author: "Xenogianni", date: "September 2, 2022", title: "Fabulous\n", text: "Great location! you can reach most of the interesting sights within walking distance. The metro is also very close. It was spacious enough for a family of four persons. Also clean and comfortable. The lady at the reception is very helpful and polite. All in all it was a great accommodation to explore Barcelona!", stars: 9, origin: "booking.com" },
        { author: "Lisbet", date: "September 1, 2022", title: "Great apartment in great location", text: "Claris apartments exceeded our expectations, which is a bit unusual - Really nice staff that we met on arrival and the apartment was clean, presentable and nice overall. We could walk around to most of the major sights, so super good location for us.", stars: 5, origin: "google.com" },
        { author: "Alina", date: "July 22, 2022", title: "Feel the heartbeat of wonderful Barcelona in the iconic Eixample district!\n", text: "Location was absolutely fantastic! 3 minute walk to Bus touristic station as well as to the nearest subway Diagonal and of course stunning iconic La Pedrera directly from your balcony:) my advise to visit a night excursion and watch wonderful perspective of a never sleeping city:) no need to mention there are dozens of wonderful small local restaurants, coffee shops, patisseries etc. - everything you need during your stay. The staff was very helpful being at reception all day around until the 7 pm and via WhatsApp the rest of the day.", stars: 10, origin: "booking.com" },
        { author: "James", date: "July 12, 2022", title: "Very Nice Apartment in Great Location for a Traveler.\n", text: "Very large apartment by European standards, essentially \"American\" sized. Two good size bedrooms, large dining/living room, medium kitchen, nice bathroom with shelving (great for several travelers), entry/desk area. Perfect location. Walked two streets west for numerous restaurants along Catalunya, two blocks to two metro stops, one block to La Pedrera, which allowed us to really enjoy it during mornings and evenings without crowds, small market on ground level, taxi stand in front. Very clean and neat. We focused on location, and although priced a little more than some we considered, we were very pleased with the apartment home for our 5 day stay and would definitely stay here again.", stars: 9, origin: "booking.com" },
        { author: "Dejana", date: "May 13, 2022", title: "Very good\n", text: "The location is great, the double bed, bedding and pillows are comfortable. There was salt and sugar in the apartment. Everything is clean. Only the bathroom door creaked a lot, it's awkward at night when you go to the toilet. The host Ivana is a very kind and accommodating person. She would stay there again. Everything is close, fashion shops are in the neighbourhood, restaurants, Spar…", stars: 9, origin: "booking.com" },
        { author: "Carlos", date: "January 10, 2021", title: "Excellent facilities..", text: "Excellent facilities. Comfortable and spacious apartment. Very close to everything. Recommendable. ", stars: 5, origin: "google.com" },
    ];
    const [reviewIndex, setReviewIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setReviewIndex(reviewIndex => (reviewIndex + 1) % (reviews.length - 1));
        }, 6000);
        return () => clearInterval(interval);
    }, [reviews.length]);


    return (
        <>
            <section className="reviewContainer">
                <h2>WHAT PEOPLE ARE SAYING</h2>
                <Segment padded style={{height:"160px"}} raised={true}>
                    <Item.Group className={"override-ui-items"} >
                        <Item style={{ display: "flex", width: "100%" }}>
                            <div style={{ width: "25%" }}>
                                <Icon name="user circle" size="big" color={"orange"} style={{ marginLeft: "3px", marginBottom: "10px" }} />
                                <div style={{ display: "inline-block" }}>
                                    <p style={{ paddingTop: "-10px", paddingLeft: "20px", margin: "0", marginTop: "-3px" }}>{reviews[reviewIndex].author}</p>

                                    <p style={{ paddingLeft: "20px", color: "darkgrey" }}>{reviews[reviewIndex].date}</p>
                                </div>
                                <div className={`${reviews[reviewIndex].origin === "expedia.com" ? "expedia-stars" : "booking-stars"} stars`}>
                                    {reviews[reviewIndex].origin === "expedia.com" && <img style={{ width: "40%" }} src={"./images/expedia.jpeg"} alt={"Expedia.com"} />}
                                    {reviews[reviewIndex].origin === "booking.com" && <img style={{ width: "40%", marginRight: "-6px" }} src={"./images/bookingcom.svg"} alt={"Booking.com"} />}
                                    {reviews[reviewIndex].origin === "google.com" && <img style={{ width: "30%", marginBottom: "-4px", marginRight: "-6px"}} src={"./images/google.png"} alt={"Google.com"} />}
                                    {(reviews[reviewIndex].origin === "expedia.com" || reviews[reviewIndex].origin === "google.com") && <span style={{ paddingLeft: "20px" }}>{Array(5).fill(0).map((_, i) => reviews[reviewIndex].stars >= i ? <Icon name="star" color="yellow" /> : <Icon name="star" color="grey" />)}</span>}
                                    {reviews[reviewIndex].origin === "booking.com" && <section><Label className={"override-ui-label"} color={"blue"}>
                                        {`${reviews[reviewIndex].stars},0`} </Label></section>}
                                </div>
                            </div>
                            <div style={{ width: "8%" }}>

                            </div>

                            <Item.Content style={{ width: "60%" }}>
                                <Item.Header as='a'>{reviews[reviewIndex % reviews.length].title}</Item.Header>
                                <Item.Meta>{reviews[reviewIndex].text}</Item.Meta>

                            </Item.Content>
                        </Item>
                    </Item.Group>

                </Segment>
            </section>
        </>
    );
}