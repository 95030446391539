import React from 'react';

import ClarisFooter from "../../common/ClarisFooter";

export default function ES_LegalPage() {

    return (
        <main className={"homeContainer"}>
            <div style={{ width: "80%", margin: "0 auto" }}>
                <h2 style={{ marginTop: "20px", marginBottom: "40px" }}>AVISO LEGAL</h2>

                <p>En cumplimiento de la Ley 34/2002, de 11 de julio, de servicios de la sociedad de la información y de comercio electrónico (LSSI), le informamos de que la presente página web con dirección www.clarisapartments.com, es propiedad de la empresa Silmar Apartments S.L. , con CIF B65842452 con domicilio en Carrer Pau Claris, 178. 08037 Barcelona y correo electrónico info@clarisapartments.com, en adelante «la Empresa», e inscrita en el Registro mercantil de Barcelona al tomo 43304 folio 64 hoja B424019 inscripción 1.</p> 

                <h4>CONDICIONES GENERALES DE USO</h4>
                <p>Las presentes condiciones generales de uso, regulan los
términos y condiciones de acceso y uso de este sitio web, propiedad de la Empresa, que el
usuario del Portal deberá de leer y aceptar para usar todos los servicios e información que se
facilitan desde el portal. El mero acceso y/o utilización del portal, de todos o parte de sus
contenidos y/o servicios significa la plena aceptación de las presentes condiciones generales
de uso. También regulan el acceso y la utilización del portal, incluyendo los contenidos y los
servicios puestos a disposición de los usuarios en y/o a través del portal, bien por el portal,
bien por sus usuarios, bien por terceros. No obstante, el acceso y la utilización de ciertos
contenidos y/o servicios puede encontrarse sometido a determinadas condiciones específicas.</p>

                <h4>Modificaciones</h4>
                <p>La empresa se reserva la facultad de modificar en cualquier momento las
condiciones generales de uso del portal. En todo caso, se recomienda que consulte
periódicamente los presentes términos de uso del portal, ya que pueden ser modificados.</p>

                <h4>Obligaciones del Usuario</h4>
                <p>El usuario deberá respetar en todo momento los términos y
condiciones establecidos en las presentes condiciones generales de uso del portal. De forma
expresa el usuario manifiesta que utilizará el portal de forma diligente y asumiendo cualquier
responsabilidad que pudiera derivarse del incumplimiento de las normas. Así mismo, el usuario
no podrá utilizar el portal para transmitir, almacenar, divulgar promover o distribuir datos o
contenidos que sean portadores de virus o cualquier otro código informático, archivos o
programas diseñados para interrumpir, destruir o perjudicar el funcionamiento de cualquier
programa o equipo informático o de telecomunicaciones.</p>

                <h4>Propiedad industrial e intelectual</h4>
                <p>Los contenidos de la página web de la Empresa son
propiedad de la Empresa. Por la presente se reserva todo derecho que no se haya otorgado
explícitamente. Se prohíbe la reproducción, transferencia, distribución o almacenamiento de
los contenidos, ya sea en parte o en su totalidad, por cualquier medio, sin la autorización
previa por escrito de la Empresa, a excepción de lo establecido en las siguientes condiciones.
La Empresa le permite navegar por su página web con su ordenador e imprimir copias de
extractos de dichas páginas exclusivamente para su uso personal y no para su distribución,
salvo en caso de autorización por escrito de la Empresa.</p>
                <p>Todos los documentos de nuestra página web pueden estar sujetos a otras condiciones,
indicadas en los mismos. Los contenidos de la página web de la Empresa se ofrecen tal y como
aparecen y según su disponibilidad. La Empresa no garantiza la ausencia de interrupciones o
errores en su página web.</p>
                <p>La Empresa se reserva el derecho de revisar la página o impedir el acceso a dicha página en
cualquier momento. La Empresa y sus símbolos son marcas registradas. Otros productos o
nombres de empresas mencionados en esta página son o pueden ser marcas registradas de
sus respectivos propietarios.</p>


                <h4>Responsabilidad</h4>
                <p>El usuario será el único responsable de las infracciones en que pueda incurrir
o de los perjuicios que pueda causar por la utilización de la página web, quedando la Empresa
libre de cualquier responsabilidad basada de la utilización del servicio por el usuario,
asumiendo el usuario cuantos gastos, costes e indemnizaciones sean solicitados a la Empresa
con motivo de reclamaciones o acciones legales. La Empresa declina cualquier responsabilidad
respecto a la información que se halle fuera de esta página web y no gestionada directamente
por su gestor de página web.</p>
                <p>En el caso de que la Empresa sea advertida de la realización por el usuario, a través de los
servicios facilitados por la Empresa de posibles actividades que pudieran ser ilegales contra
derechos de terceros o constitutivas de delito, la Empresa podrá rescindir de forma inmediata
su relación con el usuario y tomar cuantas medidas sean necesarias para evitar la continuación
de tales actividades. La Empresa no garantiza, de ningún modo, las condiciones y correcta
prestación de los productos o servicios ofertados a los usuarios por terceros, a los que se
puede acceder mediante enlaces establecidos en la página web de la Empresa</p>
                <p>La Empresa no se responsabiliza del cumplimiento por estos terceros de la normativa vigente
en nuestro ordenamiento jurídico y, en especial, de la relativa a protección de datos de
carácter personal y comercio electrónico. La Empresa no será responsable de posibles daños o
perjuicios que se pudieran derivar de interferencias omisiones, interrupciones, averías
telefónicas, virus informáticos o desconexiones en el funcionamiento operativo de este
sistema electrónico, motivadas por causas ajenas a la Empresa de retrasos o bloqueos en el
uso del presente sistema electrónico causados por deficiencias o sobrecargas de líneas
telefónicas o sobrecargas en el sistema de Internet o en otros sistemas electrónicos, así como
de daños que puedan ser ocasionados por terceras personas mediante intromisiones ilegítimas
fuera del control de la Empresa</p>

                <h4>Jurisdicción</h4>
                <p>Para cuantas cuestiones surjan sobre la interpretación, aplicación y cumplimiento
de estas condiciones y de las reclamaciones que puedan derivarse de su uso, las partes se
someten a los jueces y tribunales españoles, renunciando los interesados, de forma expresa, a
cualquier otro fuero que pudiera corresponderles, sin perjuicio de la competencia que resulte
por ministerio de la ley.</p>
                <h4>Ley aplicable</h4>
                <p>Las presentes condiciones se regirán, en todo caso, por la legislación española.
Se someten expresamente a los tribunales de Barcelona (España).</p>
           </div>
            <ClarisFooter />
        </main >
    );
}