import React from "react";

import './ClarisFooter.css'
import {useHistory} from "react-router-dom";
import { Link } from "@material-ui/core";

export default function ClarisFooter(){

    const {push} = useHistory();
    return (
        <footer>
            <div className="footerContainer">
            <section className="footerAddress">
                <h6>Address</h6>
                <a href="https://www.google.dk/maps/place/Claris+Apartments/@41.3958421,2.1612033,17z/data=!3m1!4b1!4m8!3m7!1s0x12a4a2f1c7288e17:0xce6e2ee06305f9d2!5m2!4m1!1i2!8m2!3d41.3958381!4d2.163392"><p>Claris Apartments</p><p>Pau Claris 178</p><p>Barcelona 08037 Spain</p></a>
            </section>
            <section className="footerContactInfo">
                <h6>Contact Info</h6>
                <a id="contact_tlf" href="tel:+34-933-081-164"><p>(+34) 933 081 164</p></a>
                <a id="contanct_email" href = "mailto: info@clarisapartments.com"><p>info@clarisapartments.com</p></a>
                <p>© Copyright Silmar Apartments SL T/A Claris Apartments</p>
            </section>
            <section className="footerLegalInfo">
                <h6>Legal information</h6>
                <Link underline="none" className="footerLegalInfoLink"><p onClick={() => push("/terms-of-use")}>Terms of Use</p></Link>
                <Link underline="none" className="footerLegalInfoLink"><p onClick={() => push("/privacy-policy")}>Privacy Policy</p></Link>
                <Link underline="none" className="footerLegalInfoLink"><p onClick={() => push("/legal-notice")}>Disclaimer</p></Link>
            </section>
        </div>
        </footer>
    );
}