import React from 'react';

import ClarisFooter from "../../common/ClarisFooter";

export default function LegalPage() {

    return (
        <main className={"homeContainer"}>
            <div style={{ width: "80%", margin: "0 auto" }}>
                <h2 style={{ marginTop: "20px", marginBottom: "40px" }}>LEGAL NOTICE</h2>

                <p>In compliance with Law 34/2002, of 11 July, on information society and electronic commerce services (LSSI), we inform you that this website with the address www.clarisapartments.com, is the property of the company Silmar Apartments S.L., with tax identification number B65842452, with address a Pau Claris street, number 178, 08037 Barcelona and e-mail info@clarisapartments.com, hereinafter referred to as "the Company", and registered in the Barcelona Mercantile Register in volume 43304, file 64, page B424019, entry 1.</p>

                <h4>GENERAL CONDITIONS OF USE</h4>
                <p>These general conditions of use regulate the terms and conditions of access and use of this website, owned by the Company, which the user of the Portal must read and accept to use all the services and information provided from the portal. The mere access and/or use of the portal, all or part of its contents and/or services means the full acceptance of these general conditions of use. They also regulate access to and use of the portal, including the contents and services made available to users on and/or through the portal, either by the portal or by its users or by third parties. However, access to and use of certain content and/or services may be subject to certain specific conditions.</p>

                <h4>Modifications</h4>
                <p>The company reserves the right to modify at any time the general conditions of use of the portal. In any case, it is recommended that you periodically consult these terms of use of the portal, as they may be modified.</p>

                <h4>Obligations of the User </h4>
                <p>The user must at all times respect the terms and conditions established in these general conditions of use of the portal. The user expressly declares that he or she will use the portal diligently and will assume any responsibility that may arise from non-compliance with the rules. Likewise, the user may not use the portal to transmit, store, disseminate, promote or distribute data or contents that are carriers of viruses or any other computer code, files or programs designed to interrupt, destroy or impair the operation of any computer program or equipment or telecommunications.</p>

                <h4>Industrial and intellectual property</h4>
                <p>The contents of the Company's website are the property of the Company. Any rights not expressly granted herein are reserved. Reproduction, transfer, distribution or storage of the contents, in whole or in part, by any means whatsoever, is prohibited without the prior written permission of the Company, except as provided in the following conditions. The Company allows you to browse its website with your computer and to print copies of extracts from its websites for your personal use only and not for distribution, except with the Company's written permission.</p>
                <p>All documents on our website may be subject to other conditions, as indicated on them. The contents of the Company's website are provided on an "as is" and "as available" basis. The Company does not guarantee the absence of interruptions or errors in its website. </p>
                <p>The Company reserves the right to revise the Site or to prevent access to the Site at any time. The Company and its symbols are registered trademarks. Other product or company names mentioned on this site are or may be trademarks of their respective owners. </p>


                <h4>Liability</h4>
                <p>The user shall be solely responsible for any infringements that may be incurred or damages that may be caused by the use of the website, the Company being free of any liability based on the use of the service by the user, the user assuming any costs, expenses and compensation that may be requested from the Company as a result of complaints or legal actions. The Company declines all responsibility for any information that is outside this website and not directly managed by its website manager. </p>
                <p>In the event that the Company is advised of any activity by the user, through the services provided by the Company that may be illegal against the rights of third parties or constitute a crime, the Company may immediately terminate its relationship with the user and take all necessary measures to prevent the continuation of such activities. The Company does not guarantee, in any way, the conditions and correct provision of the products or services offered to users by third parties, which may be accessed through links established on the Company's website.</p>
                <p>The Company is not responsible for the compliance of these third parties with the regulations in force in our legal system and, in particular, those relating to the protection of personal data and electronic commerce. The Company shall not be liable for any possible damages that may arise from interferences, omissions, interruptions, telephone breakdowns, computer viruses or disconnections in the operation of this electronic system, caused by causes beyond the Company's control due to delays or blockages in the use of this electronic system caused by deficiencies or overloads of telephone lines or overloads in the Internet system or in other electronic systems, as well as for damages that may be caused by third parties through illegitimate interference beyond the Company's control.</p>

                <h4>Jurisdiction </h4>
                <p>For any questions that may arise regarding the interpretation, application and fulfilment of these conditions and the claims that may arise from their use, the parties submit to the Spanish courts and tribunals, the interested parties expressly renouncing any other jurisdiction that may correspond to them, without prejudice to the jurisdiction that may result from the law. </p>

                <h4>Applicable law</h4>
                <p>These conditions shall be governed, in any case, by Spanish law. They are expressly submitted to the courts of Barcelona (Spain). </p>
           </div>
            <ClarisFooter />
        </main >
    );
}