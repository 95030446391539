import React from 'react';

import ClarisFooter from "../../common/ClarisFooter";

export default function FR_LegalPage() {

    return (
        <main className={"homeContainer"}>
            <div style={{ width: "80%", margin: "0 auto" }}>
                <h2 style={{ marginTop: "20px", marginBottom: "40px" }}>AVIS JURIDIQUE</h2>

                <p>Conformément à la loi 34/2002 du 11 juillet sur les services de la société de l&#39;information et du
commerce électronique (LSSI), nous vous informons que cette page Web avec l&#39;adresse
www.clarisapartments.com, appartient à la société Silmar Apartments S.L. , avec CIF
B65842452 avec adresse Carrer Pau Claris, 178. 08037 Barcelone et email
info@clarisapartments.com, ci-après &quot;la Société&quot;, et inscrite au Registre du Commerce de
Barcelone au volume 43304 folio 64 feuille B424019 inscription 1.</p> 

                <h4>CONDITIONS GÉNÉRALES D&#39;UTILISATION</h4>
                <p>Les présentes conditions générales d&#39;utilisation
régissent les conditions d&#39;accès et d&#39;utilisation de ce site Web, propriété de la Société, que
l&#39;utilisateur du site d'internet doit lire et accepter pour utiliser tous les services et informations
fournis par le site d'internet. Le simple accès et/ou utilisation du site d'internet, de tout ou
partie de ses contenus et/ou services vaut acceptation pleine et entière des présentes
conditions générales d&#39;utilisation. Elles réglementent également l&#39;accès et l&#39;utilisation du site
d'internet, y compris le contenu et les services mis à la disposition des utilisateurs sur et/ou via
le site d'internet, soit par le site d'internet, soit par ses utilisateurs, soit par des tiers.
Toutefois, l&#39;accès et l&#39;utilisation de certains contenus et/ou services peuvent être soumis à
certaines conditions particulières.</p>

                <h4>Modifications</h4>
                <p>La société se réserve le droit de modifier à tout moment les conditions
générales d&#39;utilisation du site d'internet. Dans tous les cas, il est recommandé de consulter
périodiquement ces conditions d&#39;utilisation du site d'internet, car elles peuvent être modifiées.</p>

                <h4>Obligations de l&#39;utilisateur</h4>
                <p>L&#39;utilisateur doit respecter à tout moment les termes et conditions
établis dans les présentes conditions générales d&#39;utilisation du portail. L&#39;utilisateur déclare
expressément qu&#39;il utilisera le site avec diligence et assumera toute responsabilité pouvant
découler du non-respect des règles. De même, l&#39;utilisateur ne peut utiliser le portail pour
transmettre, stocker, divulguer, promouvoir ou distribuer des données ou des contenus
porteurs de virus ou de tout autre code informatique, fichiers ou programmes conçus pour
interrompre, détruire ou altérer le fonctionnement de tout programme ou équipement
informatique ou télécommunications.</p>

                <h4>Propriété industrielle et intellectuelle</h4>
                <p>Le contenu du site Internet de la Société est la
propriété de la Société. Tous les droits non expressément accordés sont par la présente
réservés. La reproduction, le transfert, la distribution ou le stockage du contenu, en partie ou
dans son intégralité, par quelque moyen que ce soit, sans l&#39;autorisation écrite préalable de la
Société, est interdit, sauf tel qu&#39;établi dans les conditions suivantes. La Société vous autorise à
naviguer sur son site Internet avec votre ordinateur et à imprimer des copies d&#39;extraits
desdites pages uniquement pour votre usage personnel et non à des fins de distribution, sauf
autorisation écrite de la Société.</p>
                <p>Tous les documents de notre site Web peuvent être soumis à d&#39;autres conditions, qui y sont
indiquées. Les contenus du site Internet de la Société sont proposés tels qu&#39;ils apparaissent et
en fonction de leur disponibilité. La Société ne garantit pas l&#39;absence d&#39;interruptions ou
d&#39;erreurs sur son site Internet.</p>
                <p>La Société se réserve le droit de réviser la page ou d&#39;empêcher l&#39;accès à ladite page à tout
moment. La Société et ses symboles sont des marques déposées. Les autres noms de produits

ou de sociétés mentionnés sur cette page sont ou peuvent être des marques déposées de leurs
propriétaires respectifs.</p>


                <h4>Responsabilité</h4>
                <p>L&#39;utilisateur sera seul responsable des infractions qui pourraient être
commises ou des dommages qui pourraient être causés par l&#39;utilisation du site Web, laissant la
Société libre de toute responsabilité basée sur l&#39;utilisation du service par l&#39;utilisateur, en
supposant que l&#39;utilisateur combien de frais, coûts et indemnités sont demandés à la Société
en raison de réclamations ou d&#39;actions en justice. La Société décline toute responsabilité quant
aux informations trouvées en dehors de ce site et non directement gérées par son responsable
de site.</p>
                <p>Dans le cas où la Société serait avertie de l&#39;exécution par l&#39;utilisateur, par le biais des services
fournis par la Société, d&#39;éventuelles activités qui pourraient être illégales contre les droits de
tiers ou constituer un crime, la Société pourra mettre fin immédiatement à sa relation avec
l&#39;utilisateur et prendre toutes les mesures nécessaires pour empêcher la poursuite de telles
activités. La Société ne garantit en aucune manière les conditions et la fourniture correcte des
produits ou services proposés aux utilisateurs par des tiers, accessibles via des liens établis sur
le site Web de la Société.</p>
                <p>La Société n&#39;est pas responsable du respect par ces tiers des réglementations en vigueur dans
notre ordre juridique et notamment en matière de protection des données personnelles et du
commerce électronique. La Société ne sera pas responsable des éventuels dommages ou
pertes pouvant résulter d&#39;interférences, d&#39;omissions, d&#39;interruptions, de pannes
téléphoniques, de virus informatiques ou de déconnexions dans le fonctionnement
opérationnel de ce système électronique, causés par des causes indépendantes de la volonté
de la Société pour des retards ou des blocages. Dans l&#39;utilisation de ce système électronique
causée par des défaillances ou des surcharges des lignes téléphoniques ou des surcharges du
système Internet ou d&#39;autres systèmes électroniques, ainsi que des dommages pouvant être
causés par des tiers par des interférences illégitimes indépendantes de la volonté de la Société</p>

                <h4>Compétence</h4>
                <p>Pour toute question relative à l&#39;interprétation, l&#39;application et le respect de ces
conditions et les réclamations pouvant découler de leur utilisation, les parties se soumettent
aux juges et tribunaux espagnols, les parties intéressées renonçant expressément à toute
autre juridiction qui pourrait leur correspondre. , sans préjudice de la compétence résultant du
ministère de la justice.</p>
                <h4>Loi applicable</h4>
                <p>Ces conditions seront régies, en tout état de cause, par la loi espagnole. Ils se
soumettent expressément aux tribunaux de Barcelone (Espagne).</p>
           </div>
            <ClarisFooter />
        </main >
    );
}