

const apartmentNames = ['One Bedroom Apartment', 'Two Bedroom Apartments', 'Terrace Apartment'];

const apartmentDescriptions = [
    [
        `Beautiful, cosy and modern air-conditioned apartment with a 10 m² interior terrace and free Wi-Fi. The spacious apartment has a living room with a sofa bed, flat-screen TV, CD player and iPod docking station.`,
        `The kitchen is fully equipped with fridge, microwave, coffee maker, washing machine and many utilities you may require during your stay. There is a hairdryer and towels in the bathroom.`
    ],
    [
        `High standard centrally located apartment, spacious, exterior and very bright. Nicely decorated in a simple manner with a touch of elegance. The kitchen is equipped with everything needed to prepare meals of your choice.`,
        `All apartments have French balconies, air conditioning and heating and Wi - fi free. The spacious apartment has a living room with a double sofa bed, flat-screen TV, CD player and iPod docking station. There is a hairdryer in the bathroom.`,
        `From the spacious bright living room, you have access to your own private terrace / solarium overlooking Gaudi's La Pedrera. The master bedroom has a queen size bed and second bedroom has two single beds , both with a large wardrobe and bedside tables. Also there is a large double sofa bed in the living room.`,
        `All ready for you to enjoy a relaxing and comfortable stay in Barcelona.`
    ],
    [
        `High standard centrally located apartment, spacious, exterior and very bright. Nicely decorated in a simple manner with a touch of elegance. The kitchen is equipped with everything needed to prepare meals of your choice.`,
        `All apartments have French balconies, air conditioning and heating and Wi - fi free. The spacious apartment has a living room with a double sofa bed, flat-screen TV, CD player and iPod docking station. There is a hairdryer in the bathroom.`,
        `From the spacious bright living room, you have access to your own private terrace / solarium overlooking Gaudi's La Pedrera. The master bedroom has a queen size bed and second bedroom has two single beds , both with a large wardrobe and bedside tables. Also there is a large double sofa bed in the living room.`,
        `All ready for you to enjoy a relaxing and comfortable stay in Barcelona.`
    ],
]

const apartmentImages = [
    [
        '/images/21A_one_room/21A_main_room.jpg',
        '/images/21A_one_room/21A_bedroom.jpg',
        '/images/21A_one_room/21A_dining_room.jpg',
        '/images/21A_one_room/21A_kitchen.jpg',
        '/images/21A_one_room/21A_sitting_room.jpg',
        '/images/21A_one_room/21A_sofabed.jpg',
        '/images/21A_one_room/21A_terrace.jpg',
        '/images/21A_one_room/21A_bathroom.jpg',
        '/images/21A_one_room/21A_sink.jpg',
    ],
    [
        '/images/21B_two_rooms/21B_sofa.jpg',
        '/images/21B_two_rooms/21B_sitting_room.jpg',
        '/images/21B_two_rooms/21B_tableroom.jpg',
        '/images/21B_two_rooms/21B_twin_room.jpg',
//        '/images/21B_two_rooms/21B_lobby.jpg',
//        '/images/21B_two_rooms/21B_corridor1.jpg',
//        '/images/21B_two_rooms/21B_corridor2.jpg',
        '/images/21B_two_rooms/21B_balcony.jpg',
        '/images/32_two_rooms/32_double_room.jpg',
        '/images/32_two_rooms/32_dining_room.jpg',
        '/images/32_two_rooms/32_main_room.jpg',
        '/images/32_two_rooms/32_main_room2.jpg',
        '/images/32_two_rooms/32_second_bedroom.jpg',
//        '/images/32_two_rooms/32_second_bedroom2.jpg',
//        '/images/32_two_rooms/32_kitchen.jpg',
//        '/images/32_two_rooms/32_kitchen2.jpg',
//        '/images/32_two_rooms/32_sitting_room2.jpg',
//        '/images/32_two_rooms/32_sitting_room3.jpg',
//        '/images/32_two_rooms/32_bathroom.jpg',
//        '/images/32_two_rooms/32_views.jpg',
//        '/images/42_two_rooms/42_dining_room.jpg',
//        '/images/42_two_rooms/42_twin_room.jpg',
        '/images/42_two_rooms/42_twin_room2.jpg',
        '/images/42_two_rooms/42_triple.jpg',
        //'/images/42_two_rooms/42_triple2.jpg',
        //'/images/42_two_rooms/42_triple3.jpg',
        //'/images/42_two_rooms/42_tv.jpg',
        '/images/42_two_rooms/42_table_room.jpg',
        '/images/42_two_rooms/42_sitting_room.jpg',
        '/images/42_two_rooms/42_lobby.jpg',
        //'/images/42_two_rooms/42_kitchen.jpg',
        '/images/42_two_rooms/42_kitchen2.jpg',
        //'/images/42_two_rooms/42_bathroom1.jpg',
        '/images/42_two_rooms/42_bathroom2.jpg',
        '/images/42_two_rooms/42_views.jpg',
        '/images/42_two_rooms/42_vistas.jpg',
    ],
    [
        '/images/51_attic/51_dining_room.jpg',
        '/images/51_attic/51_double_room.jpg',
        '/images/51_attic/51_sitting_room1.jpg',
        '/images/51_attic/51_sitting_room3.jpg',
        '/images/51_attic/51_twin_room1.jpg',
        '/images/51_attic/51_twin_room2.jpg',
        '/images/51_attic/51_twin_room3.jpg',
        '/images/51_attic/51_kitchen.jpg',
        '/images/51_attic/51_bathroom.jpg',
        '/images/51_attic/51_terrace1.jpg',
    ],
]


export { apartmentNames, apartmentDescriptions, apartmentImages }