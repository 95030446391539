import React from 'react';

import './SlideShower.css';


import AliceCarousel from 'react-alice-carousel'
import 'react-alice-carousel/lib/alice-carousel.css'

type CaptionProps = {
    caption: string
    subCaption: string
}

function SimpleCaption(props: CaptionProps){
    return (
        <div className="sliderCaption">
            <h1>{props.caption}</h1>
            <h3>{props.subCaption}</h3>
        </div>
    );
}


export default function SlideShower(){
    // @ts-ignore
    const handleOnDragStart = (e) => e.preventDefault()
    return (
            <section className="slideshowContainer">

                <AliceCarousel mouseTrackingEnabled buttonsDisabled={true} autoHeight={true} autoPlay={true} autoPlayInterval={5000}>
                    <img src="./images/barcelona-street-of-colors.jpg" alt={"barcelona"} onDragStart={handleOnDragStart} className="apartment_image" />
                    <img src="./images/apartment_2.jpg" alt={"barcelona"} onDragStart={handleOnDragStart} className="apartment_image" />
                    <img src="./images/apartment_9.jpg" alt={"barcelona"} onDragStart={handleOnDragStart} className="apartment_image" />
                    <img src="./images/apartment_3.jpg" alt={"barcelona"} onDragStart={handleOnDragStart} className="apartment_image" />
                </AliceCarousel>
                <SimpleCaption caption={"APARTMENTS"} subCaption={"AT THE HEART OF BARCELONA"}/>
            </section>

    );
}



/*<SmartSlider Slides={slidesArray} autoSlide={true} buttonShape={'round'} autoSlideInterval={6000}/>*/
