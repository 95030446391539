import React, { useEffect, useState } from 'react';

import './ApartmentPage.css'
import About from "../home/About";
import ClarisFooter from "../common/ClarisFooter";
import { apartmentNames, apartmentDescriptions, apartmentImages } from '../apartments';
import { ApartmentImage } from './ApartmentImage';
import {useParams} from 'react-router-dom';

const apartmentNumbers = ["one-bedroom-apartment", "two-bedroom-apartment", "terrace-apartment"];

export default function ApartmentPage() {
    const { nr } = useParams();
    const [isVisible, setIsVisible] = useState(false);
    const [index, setIndex] = useState(0);

    useEffect(() => {
        window.scrollTo(0, 0)
        setIndex(apartmentNumbers.findIndex(a => a === nr));
        setTimeout(() => setIsVisible(true), 700)
    }, [nr]);

    return (
        <main className={isVisible ? "homeContainer" : "homeContainerHide"}>
            <div className="apartment_page_container">
                <h2 style={{ marginTop: "20px", marginBottom: "40px" }}>{apartmentNames[index]}</h2>
                <div className={"apartment_page_main"}>
                    <div className='apartment_page_img'>
                        <ApartmentImage apartmentImages={apartmentImages[index]} />
                    </div>
                    <div className={"apartment_page_description"}>
                        <h4>DESCRIPTION</h4>
                        {apartmentDescriptions[index].map((apartmentText, idx) =>
                            <p key={"apartmentText: " + idx}>{apartmentText}</p>
                        )}
                        <About />
                    </div>
                </div>
            </div>
            <ClarisFooter />
        </main >
    );
}