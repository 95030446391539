import React from 'react';
import ReactDOM from 'react-dom';
import 'semantic-ui-css/semantic.min.css'
import App from './App';
import {BrowserRouter as Router} from "react-router-dom";
import * as serviceWorker from './serviceWorker';



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDSy32bcYu0v-ufbYm5rZ9XMlk-NPyhnDQ",
  authDomain: "claris-apartments.firebaseapp.com",
  projectId: "claris-apartments",
  storageBucket: "claris-apartments.appspot.com",
  messagingSenderId: "92139473546",
  appId: "1:92139473546:web:d1be8c959553bb6a40bb73",
  measurementId: "G-NMV9CKKCJX"
};

// Initialize Firebase
const firebase_app = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebase_app);
logEvent(analytics, 'page loaded');





ReactDOM.render(
  <React.StrictMode>
      <Router>
          <App firebase_app={firebase_app}/>
      </Router>
  </React.StrictMode>,
  document.getElementById('root')
);