import React from 'react';

import ClarisFooter from "../../common/ClarisFooter";

export default function FR_PrivacyPage() {

    return (
        <main className={"homeContainer"}>
            <div style={{ width: "80%", margin: "0 auto" }}>
                <h2 style={{ marginTop: "20px", marginBottom: "40px" }}>POLITIQUE DE CONFIDENTIALITÉ</h2>

                <p>Cette politique de confidentialité s&#39;applique au traitement des données personnelles par
Silmar Apartments S.L. (ci-après la « Société ») au sein de ses sites Web, de son application et
de tout autre moyen électronique ou plate-forme numérique dans lequel la société traite des
données personnelles.</p>
                <p>1.- Responsable du traitement des données : Silmar Apartments S.L. avec CIF B65842452 avec
adresse Carrer de Pau Claris 178, 08037 Barcelone et email info@clarisapartments.com est
responsable du traitement des données fournies, nous informons que ces données seront
traitées conformément aux dispositions du règlement (UE) 2016 /679 du Parlement européen
et du Conseil du 27 avril 2016 relatif à la protection des personnes physiques à l&#39;égard du
traitement des données à caractère personnel et à la libre circulation de ces données.</p>
                <p>Conformément aux dispositions de la loi organique sur la protection des données 15/1999, la
société vous informe que vos données personnelles sont incorporées dans les fichiers
automatisés appartenant à la société susmentionnée.</p>
                
                <p>2.- Finalité du traitement : il s&#39;agit de maintenir une relation commerciale avec l&#39;utilisateur des
sites Web, de l&#39;application et de tout autre moyen électronique ou plate-forme numérique de
l&#39;entreprise. Pour réaliser le traitement, les opérations prévues sont :</p>
                <p>- Traiter les commandes, demandes ou tout type de demande faite par l&#39;utilisateur via
l&#39;un des formulaires de contact mis à sa disposition.</p>
                <p>- Envoi de communications publicitaires commerciales liées à nos produits et services
par tout moyen électronique ou physique, présent ou futur, permettant de réaliser des
communications commerciales.</p>
                <p>- Envoyer des enquêtes de satisfaction sur les services fournis ou liés aux services
proposés par notre société. Ces envois seront effectués par tout moyen électronique
ou physique, présent ou futur, permettant d&#39;effectuer des communications.</p>
                
                <p>3.- Conservation des données : elles seront conservées tant qu&#39;il y aura un intérêt mutuel à
maintenir la fin du traitement. Lorsqu&#39;ils ne seront plus nécessaires à cette fin, ils seront
supprimés avec des mesures de sécurité adéquates pour garantir leur destruction totale. Les
données personnelles fournies par l&#39;intéressé seront conservées même si leur suppression est
demandée par l&#39;intéressé, en limitant leur traitement, uniquement pour l&#39;accomplissement des
obligations légales et/ou l&#39;exercice ou la défense de réclamations.</p>
                
                <p>4.- L&#39;utilisateur bénéficie des droits d&#39;accès, de rectification, de suppression, d&#39;opposition et de
portabilité de ses données. Ainsi que le droit de déposer une réclamation auprès de l&#39;autorité
de contrôle, www.agpd.es, si vous considérez que le traitement n&#39;est pas conforme à la
réglementation en vigueur.</p>
                <p>Pour exercer son droit, l&#39;utilisateur doit envoyer une photocopie du D.N.I. ou passeport à :</p>
                <p>Appartements Silmar S.L. avec CIF B65842452 avec adresse Carrer de Pau Claris 178, Porteria.
08037 Barcelone et e-mail info@clarisapartments.com</p>
                
                <p>5.- Légitimation du traitement des données : La légitimité du traitement de vos données est le
consentement obtenu de l&#39;utilisateur. Les utilisateurs en cochant la case correspondante
acceptent expressément et librement et sans équivoque que leurs données soient nécessaires
pour répondre à leur demande par le prestataire. L&#39;utilisateur garantit que les données
personnelles fournies sont véridiques et est responsable de la communication de toute
modification de celles-ci.</p>
                <p>Toutes les données demandées via le service Web sont obligatoires, car elles sont nécessaires
à la fourniture du service, dans le cas où toutes les données ne sont pas fournies, il n&#39;est pas
garanti que les informations et le service fournis par le fournisseur seront ajustés .
entièrement à vos besoins.</p>
                
                <p>6.- À quels destinataires vos données seront communiquées : La &quot;Société&quot; informe et garantit
expressément les utilisateurs que leurs données personnelles ne seront en aucun cas cédées à
des tiers, sauf dans les cas où il existe une obligation légale, et si tout type de transfert de
données personnelles, le consentement exprès, éclairé et sans équivoque des utilisateurs sera
préalablement demandé.</p>
                
                <p>7.- Données de mineurs : Si vous êtes un utilisateur mineur, vous devez avoir le consentement
préalable de vos parents ou tuteurs, avant de procéder à l&#39;inclusion de vos données
personnelles sur notre site Web. La &quot;Société&quot; décline toute responsabilité en cas de non-
respect de cette exigence.</p>
                
                <p>8.- Mesures de sécurité : Conformément aux dispositions de la réglementation en vigueur en
matière de protection des données personnelles, le prestataire respecte toutes les dispositions
de la réglementation sur le traitement des données personnelles et les principes décrits à
l&#39;article 5 du RGPD .</p>
                <p>Il est garanti que des politiques techniques et organisationnelles appropriées ont été mises en
place pour appliquer les mesures de sécurité établies par le RGPD afin de protéger les droits et
libertés des utilisateurs et ont communiqué les informations appropriées afin qu&#39;ils puissent
les exercer.</p>
                
                <p>9.- Transferts internationaux de données : les utilisateurs peuvent communiquer avec la
&quot;Société&quot; via leurs profils sur les réseaux sociaux. Ces profils peuvent être situés aux États-
Unis, les transferts de données seront couverts par l&#39;accord entre les États-Unis et l&#39;Union
européenne, Privacy Shield, ou le consentement explicite de l&#39;intéressé, le cas échéant.
L&#39;accord Privacy Shield susmentionné est un instrument juridiquement contraignant et
exécutoire entre l&#39;UE et les États-Unis, qui est considéré par la Commission européenne

comme offrant des garanties adéquates pour la protection des données personnelles des
parties intéressées.</p>
            </div>
            <ClarisFooter />
        </main >
    );
}