import React from 'react';

import ClarisFooter from "../../common/ClarisFooter";

export default function ES_TermsPage() {

    return (
        <main className={"homeContainer"}>
            <div style={{ width: "80%", margin: "0 auto" }}>
                <h2 style={{ marginTop: "20px", marginBottom: "40px" }}>CONDICIONES GENERALES</h2>
                <h4>RESERVAS:</h4>
                <p>Una vez el cliente realice la reserva a través de nuestra página web, introduciendo los datos
personales y los datos de tarjeta de crédito, recibirá un correo electrónico con la confirmación
de su reserva.</p>

                <h4>PAGO DE LA RESERVA:</h4>
                <p>El pago de la reserva se realizará a la llegada al apartamento mediante tarjeta de crédito (Visa
o Mastercard), excepto para las ofertas que tienen sus propias condiciones:</p>
                <p>- En las tarifas no reembolsables, el cobro del 100% se hará en el momento de hacer la reserva.</p>

                <h4>PREAUTORIZACIÓN:</h4>
                <p>En las reservas con condiciones de cancelación gratuita de hasta 20 días o 5 días, realizaremos
una preautorización de 250 euros en la tarjeta de crédito facilitada en el momento de realizar
la reserva para garantizar la validez de esta. Este importe constituirá la fianza que será anulada
a la salida.</p>

                <h4>CAMBIO DE FECHAS DE LA ESTANCIA:</h4>
                <p>Si el cliente desea cambiar las fechas de su reserva debe comunicarlo a Claris Apartments por
medio de correo electrónico. En el caso de que haya disponibilidad en otras fechas se
efectuará el cambio sin ningún coste adicional. Si no hubiese disponibilidad en las nuevas
fechas elegidas por el cliente y este decidiera cancelar la reserva, se procedería a aplicar las
condiciones de cancelación de la reserva.</p>

                <p>En el caso de la oferta no reembolsable deberá ponerse en contacto con Claris Apartments
para valorar la posibilidad de la modificación.</p>

                <h4>CANCELACIÓN DE RESERVA:</h4>
                <p>El cliente podrá cancelar su reserva sin coste según el tipo de tarifa y condiciones que haya
reservado:</p>
                <ul>
                    <li>No reembolsable: el importe no es reembolsable en caso de cancelación</li>
                    <li>Tarifa standard: el cliente podrá cancelar sin coste alguno hasta 20 días antes de la
llegada. En caso contrario, el cliente abonará el 100% de la reserva.</li>
                    <li>Tarifa flexible: el cliente podrá cancelar sin coste hasta 5 días antes de la llegada, en
caso de cancelaciones posteriores, se deberá abonar el 100% de la reserva.</li>
                </ul>
                <p>Claris Apartments se reserva el derecho, por causas de fuerza mayor (incendio, inundación,
robo...) de cambiar el apartamento reservado por parte del cliente por otro de igual o superior
categoría.</p>

                <h4>IDENTIDAD DE LOS HUÉSPEDES:</h4>
                <p>El cliente deberá comunicar a Claris Apartments el número total de personas que ocuparán el
apartamento, así como los nombres completos, fecha de nacimiento y documento de
identidad. Únicamente las personas registradas podrán utilizarlo en función de la capacidad
máxima de cada apartamento. El cliente que realice la reserva deberá facilitar su nombre,
número de teléfono y correo electrónico al efectuar la reserva.</p>

                <h4>POLÍTICA DE NIÑOS:</h4>
                <p>Los niños menores de 2 años se alojan gratis.</p>

                <h4>POLÍTICA DE MASCOTAS:</h4>
                <p>Los animales de compañía no están permitidos en los apartamentos.</p>


                <h4>FIANZA:</h4>
                <p>En todas las reservas se deberá abonar una fianza de 250 EUR por apartamento para cubrir
posibles desperfectos.</p>

                <h4>GRUPOS:</h4>
                <p>Se considerará grupo a reservas de 2 o más apartamentos. Este tipo de reservas puede
conllevar condiciones distintas y suplementos adicionales, el departamento de reservas se
pondrá en contacto cuando se efectúe la reserva para informar de las condiciones especiales.</p>
                <p>No se aceptan grupos de despedidas de soltero/a o similares.</p>

                <h4>CHECK-IN/ CHECK-OUT:</h4>
                <p>Una vez efectuada su reserva y unos días antes de su llegada, nuestro equipo se pondrá en
contacto con usted para programar su llegada y aclarar cualquier duda. Es muy importante que
1 hora antes de su llegada a los apartamentos, se ponga de nuevo en contacto con nosotros
via email, mensaje o teléfono. De esta forma garantizamos eliminar cualquier tipo de espera
por retrasos o cambios de plantes de última hora.</p>
                <p>Horario de oficina: de lunes a viernes de 09:30 a 13:30 hrs y de 15:00 a 19:00 hrs.
Situada en el mismo edificio de los apartamentos.</p>
                <p>Check-in directamente en su apartamento a partir de las 15:00 hrs. En caso de llegar
con anterioridad, se intentará dar acceso al apartamento lo antes posible según
disponibilidad.</p>
                <p>Late check in. A partir de las 20:30 hrs se realizará un cargo extra de 30 euros que
serán abonados a la llegada. Para llegadas posteriores a las 00:00 hrs, el cargo será de
50 euros.</p>
                <p>Check out: se realizará antes de las 12:00 hrs del mediodía. Deje las llaves en la mesa
de su apartamento. Apague luces y aire acondicionado y cierre la puerta.</p>
                <p>Servicio de consigna gratuito: disponible de lunes a viernes en horario de oficina.</p>

                <h4>EQUIPAMIENTOS / SUMINISTROS / LIMPIEZA:</h4>
                <p>Todos los suministros de agua y luz están incluidos en el precio del apartamento. Los
apartamentos están totalmente equipados: cocina, ropa de cama, toallas y kit de limpieza.</p>

                <h4>IMPUESTOS:</h4>
                <p>Las reservas están sujetas a un recargo del 10% de I.V.A., incluido ya en el precio.</p>
                <p>Tasa turística: las reservas tendrán un recargo de 4,40 euros por persona y noche que se
deberá abonar a la llegada.</p>

                <h4>RESPONSABILIDADES:</h4>
                <p>Claris Apartments no se hace responsable de las consecuencias que se puedan derivar del uso
que hace el cliente durante su estancia en el apartamento, incluidos daños, heridas, pérdidas
por robo, fuego o actividad delictiva. En el caso de que durante la estancia del cliente en el
apartamento hubiera una avería ajena a un mal uso por parte del cliente e hiciera imposible su
estancia en el mismo, Claris Apartments se encargará de arreglarlo lo antes posible, previa
notificación por parte del cliente. El servicio de mantenimiento está operativo de 09:30 hrs a
19:00 hrs.</p>

                <h4>COMPORTAMIENTO DEL HUÉSPED:</h4>
                <p>El cliente y sus acompañantes deberán comportarse de una forma cívica, respetando
las normas de convivencia. Es importante respetar el descanso de otros huéspedes,
por lo que no se podrá hacer ruido entre las 22:00 y las 08:00 horas. En el caso de que
no se cumplan las normas generales de convivencia, recibir quejas de vecinos o
intervención de la policía, Claris Apartments tendrá derecho a exigir el desalojo del
apartamento sin compensación alguna.</p>
                <p>Fumar está totalmente prohibido dentro de los apartamentos. El incumplimiento de la
norma conlleva un cargo adicional de 150 euros.</p>
                <p>Debe dejar el apartamento en las mismas condiciones de limpieza y orden que
encontró a la entrada. Desde el momento de su salida revisaremos el apartamento en
un plazo de 24 h. En caso de haber algún desperfecto importante, suciedad/basura o
faltara algo, estamos autorizados a hacer el cargo correspondiente en su tarjeta de
crédito.</p>
                <p>El conserje está autorizado a entrar en el apartamento en caso de emergencia o
problemas técnicos. Si fuera necesario el conserje entrará sin previa notificación al
cliente.</p>  
                <p>En caso de olvidar la llave dentro del apartamento durante su estancia o estropear la
cerradura por un mal uso, el cliente deberá abonar el cargo correspondiente. En caso
de pérdida, o no dejar la llave a su salida, el cliente deberá abonar 60 euros por juego
de llaves, que podrá ser cargado en la tarjeta de crédito facilitada como garantía.</p>
                <p>Ni nuestra empresa, ni la Central de Reservas con quien realizó su reserva se harán
responsables por cualquier daño directo o indirecto causado por el cliente durante su
estancia en los apartamentos. Las quejas que pueda tener del apartamento debe
hacerlas durante su estancia. No aceptamos reclamaciones fuera de este periodo.</p>

                <h4>REGISTRO DE ACTIVIDAD TURÍSTICA DE CLARIS APARTMENTS:</h4>
                <p>Licencias Turística</p>
                <ul>
                <li>HUTB00266359</li>
                <li>HUTB00266454</li>
                <li>HUTB00755978</li>
                <li>HUTB00266252</li>
                </ul>
            </div>
            <ClarisFooter />
        </main >
    );
}