import React from "react";
import WifiOutlinedIcon from '@material-ui/icons/WifiOutlined';
import AcUnitOutlinedIcon from '@material-ui/icons/AcUnitOutlined';
import TvOutlinedIcon from '@material-ui/icons/TvOutlined';
import KingBedOutlinedIcon from '@material-ui/icons/KingBedOutlined';
import BathtubOutlinedIcon from '@material-ui/icons/BathtubOutlined';
import FireplaceIcon from '@material-ui/icons/Fireplace';
import HeightOutlinedIcon from '@material-ui/icons/HeightOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import MeetingRoomOutlinedIcon from '@material-ui/icons/MeetingRoomOutlined';
import LocalParkingOutlinedIcon from '@material-ui/icons/LocalParkingOutlined';
import SingleBedOutlinedIcon from '@material-ui/icons/SingleBedOutlined';
import EjectOutlinedIcon from '@material-ui/icons/EjectOutlined';
import Crop32OutlinedIcon from '@material-ui/icons/Crop32Outlined';
import { PeopleOutline, VideocamOutlined } from "@material-ui/icons";
import AssignmentIcon from '@material-ui/icons/Assignment';
import WorkIcon from '@material-ui/icons/Work';
import AmpStoriesIcon from '@material-ui/icons/AmpStories';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';

import './About.css';

import * as Scroll from 'react-scroll';
import { CropSquareOutlined } from "@material-ui/icons";
let Element = Scroll.Element;



export default function About() {
    return (
        <Element name="aboutElement">
            <section className="aboutSection">
                {window.location.pathname === "/apartment" ? <h4>FEATURES</h4> : <h2>FEATURES</h2>}
                <div className="featuresContainer">
                    <div className="featuresFlex">
                        <div className="feature">
                            <WifiOutlinedIcon /*style={{ color:"orange"}}*/ />
                            <h5>WIFI</h5>
                        </div>
                        <div className="feature">
                            <AcUnitOutlinedIcon />
                            <h5>Air Conditioning</h5>
                        </div>
                        <div className="feature">
                            <TvOutlinedIcon />
                            <h5>Flat screen TV</h5>
                        </div>
                        <div className="feature">
                            <KingBedOutlinedIcon />
                            <h5>Sheets</h5>
                        </div>
                        <div className="feature">
                            <BathtubOutlinedIcon />
                            <h5 style={{"fontSize": "0.95em"}}>Bathroom with shower</h5>
                        </div>
                        <div className="feature">
                            <img src="https://img.icons8.com/material-outlined/24/000000/hair-dryer.png" alt={"hair dryer"} />
                            <h5>Hairdryer</h5>
                        </div>
                        <div className="feature">
                            <CropSquareOutlined />
                            <h5>Towel</h5>
                        </div>
                        <div className="feature">
                            <FireplaceIcon />
                            <h5>Heating</h5>
                        </div>
                        <div className="feature">
                            <BathtubOutlinedIcon />
                            <h5>Shower gel</h5>
                        </div>
                        <div className="feature">
                            <AmpStoriesIcon />
                            <h5>Lift</h5>
                        </div>
                        <div className="feature">
                            <FireplaceIcon />
                            <h5>Fire extinguisher</h5>
                        </div>
                        <div className="feature">
                            <InfoOutlinedIcon />
                            <h5>Safety information</h5>
                        </div>
                        <div className="feature">
                            <MeetingRoomOutlinedIcon />
                            <h5>Doorbell</h5>
                        </div>
                        <div className="feature">
                            <VideocamOutlined />
                            <h5>Security cameras</h5>
                        </div>
                        <div className="feature">
                            <Crop32OutlinedIcon />
                            <h5>Ironing board</h5>
                        </div>
                        <div className="feature">
                            <EjectOutlinedIcon />
                            <h5>Iron</h5>
                        </div>
                        <div className="feature">
                            <PersonOutlineIcon />
                            <h5>Reception</h5>
                        </div>

                    </div>
                </div>
                <div className="additionalFeaturesContainer">
                    <h4>Additional services upon request and availability (some with additional cost):</h4>
                    <div className="featuresFlex">
                        <div className="feature">
                            <LocalParkingOutlinedIcon />
                            <h5>Public Parking Area</h5>
                        </div>
                        <div className='feature'>
                            <SingleBedOutlinedIcon />
                            <h5>Crib</h5>
                        </div>
                        <div className="feature">
                            <AssignmentIcon />
                            <h5>Tourist infomation</h5>
                        </div>
                        <div className="feature">
                            <WorkIcon />
                            <h5>Luggage room</h5>
                        </div>
                    </div>
                </div>
            </section>
        </Element>
    );
};