import React from 'react';

import ClarisFooter from "../../common/ClarisFooter";

export default function ES_PrivacyPage() {

    return (
        <main className={"homeContainer"}>
            <div style={{ width: "80%", margin: "0 auto" }}>
                <h2 style={{ marginTop: "20px", marginBottom: "40px" }}>POLÍTICA DE PRIVACIDAD</h2>

                <p>La presente Política de Privacidad es aplicable al tratamiento de datos personales por parte de
Silmar Apartments S.L. (en adelante la “Empresa”) dentro de sus webs, app y cualquier otro
medio electrónico o plataforma digital en las que la empresa trate datos personales.</p>
                <p>1.- Responsable del tratamiento de los datos: Silmar Apartments S.L. con CIF B65842452 con
domicilio en Carrer de Pau Claris 178, 08037 Barcelona y correo electrónico
info@clarisapartments.com es el responsable del tratamiento de los datos facilitados,
informamos que estos datos serán tratados de conformidad con lo dispuesto en el Reglamento
(UE) 2016/679 del Parlamento Europeo y del Consejo, de 27 de abril de 2016 relativo a la
protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la
libre circulación de estos datos.</p>
                <p>En cumplimiento de lo que dispone la Ley Orgánica de Protección de datos 15/1999, la
empresa, les informa que sus datos personales están incorporados en los ficheros
automatizados de los que es titular la susodicha empresa.</p>
                
                <p>2.- Finalidad del Tratamiento: Es mantener una relación comercial con el usuario de las webs,
app y cualquier otro medio electrónico o plataforma digital de la empresa. Para realizar el
tratamiento las operaciones previstas son:</p>
                <p>- Tramitar encargos, solicitudes o cualquier tipo de petición que sea realizada por el
usuario a través de cualquiera de las formas de contacto que se ponen a su
disposición.</p>
                <p>- Envío de comunicaciones comerciales publicitarias relacionadas con nuestros
productos y servicios por cualquier medio electrónico o físico, presente o futuro, que
posibilite realizar comunicaciones comerciales.</p>
                <p>- Envío de encuestas de satisfacción de los servicios prestados o relacionados con los
servicios ofrecidos por nuestra empresa. Dichos envíos se harán por cualquier medio
electrónico o físico, presente o futuro, que posibilite realizar comunicaciones.</p>
                
                <p>3.- Conservación de los datos: se conservarán mientras exista un interés mutuo de mantener el
fin del tratamiento. Cuando ya no sean necesarios para tal fin se suprimirán con medidas de
seguridad adecuadas para garantizar la total destrucción de estos. Los datos personales
proporcionados por el interesado se mantendrán aun solicitada su supresión por parte de este,
limitando su tratamiento, únicamente para el cumplimiento de obligaciones legales y/o el
ejercicio o la defensa de reclamaciones.</p>
                
                <p>4.- Al usuario le asisten los derechos de acceso, rectificación, supresión, oposición y
portabilidad de sus datos. Al igual que el derecho a presentar una reclamación ante la
autoridad de control, www.agpd.es, si considera que el tratamiento no se ajusta a la normativa
vigente.</p>
                <p>Para ejercer su derecho, el usuario debe enviar fotocopia del D.N.I. o pasaporte a:</p>
                <p>Silmar Apartments S.L. con CIF B65842452 con domicilio en Carrer de Pau Claris 178, porteria.
08037 Barcelona y correo electrónico info@clarisapartments.com</p>
                
                <p>5.- Legitimación del tratamiento de los datos: La legitimación para el tratamiento de sus datos
es el consentimiento obtenido del usuario. Los usuarios mediante la marcación de la casilla
correspondiente aceptan expresamente y de forma libre e inequívoca, que sus datos son
necesarios para atender su petición por parte del prestador. El usuario garantiza que los datos
personales facilitados son veraces y se hace responsable de comunicar cualquier modificación
de estos.</p>
                <p>Todos los datos solicitados a través del servicio web son obligatorios, ya que son necesarios
para la prestación del servicio, en caso de que no se faciliten todos los datos, no se garantiza
que la información y el servicio que el prestador le facilite, sean ajustados completamente a
sus necesidades.</p>
                
                <p>6.- A que destinatarios se comunicaran sus datos: La “Empresa” informa y garantiza
expresamente a los usuarios que sus datos personales no serán cedidos en ningún caso a
terceros salvo en los casos en que exista una obligación legal, y si se realizara algún tipo de
cesión de datos personales, se pedirá previamente el consentimiento expreso, informado e
inequívoco por parte de los usuarios.</p>
                
                <p>7.- Datos de Menores: Si eres una persona usuaria menor de edad has de contar con el previo
consentimiento de tus padres o tutores, antes de proceder a la inclusión de tus datos
personales en nuestra web. La “Empresa” se exime de cualquier responsabilidad por el
incumplimiento de este requisito.</p>
                
                <p>8.- Medidas de Seguridad: De conformidad con lo dispuesto en las normativas vigentes en
materia de protección de datos personales, el prestador está cumpliendo con todas las
disposiciones de las normativas para el tratamiento de datos personales y los principios
descritos en el artículo 5 del RGPD.</p>
                <p>Se garantiza que se han implementado políticas técnicas y organizativas apropiadas para
aplicar las medidas de seguridad que establece el RGPD con el fin de proteger los derechos y
libertades de los usuarios y les ha comunicado la información adecuada para que puedan
ejercerlos.</p>
                
                <p>9.- Transferencias Internacionales de Datos: Los usuarios pueden comunicarse con la
“Empresa” a través de sus perfiles en redes sociales. Estos perfiles pueden estar ubicados en
Estados Unidos, las transferencias de datos estarán amparadas por el acuerdo entre EEUU y la
Unión Europea, Privacy Shield, o el consentimiento explícito del interesado, en su caso. El
citado acuerdo Privacy Shield es un instrumento jurídicamente vinculante y exigible entre la UE
y EEUU, que es considerado por la Comisión Europea que facilita garantías adecuadas para la
protección de los datos personales de los interesados.</p>
            </div>
            <ClarisFooter />
        </main >
    );
}