import React from 'react';

import SlideShower from "./SlideShower";
import InfoColumns from "./InfoColumns";
import Apartments from "./Apartments";
import Location from "./Location";
import About from "./About";
import ClarisFooter from "../common/ClarisFooter";
import Reviews from "./Reviews";

interface HomePageProps {
    onClickApartments: (aNr: string) => void
}

export default class HomePage extends React.Component<HomePageProps, any>{

    state = {
        currentPage: "home",
        scrollTop: 0,
        currentApartment: 1
    };

    onClickApartment(apartmentNumber: string): void {
        this.props.onClickApartments(apartmentNumber)
    }

    componentDidMount() {
        setTimeout(() => window.addEventListener('scroll', (event) => this.handleScroll(event)), 1000)
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', (event) => this.handleScroll(event));
    }

    // @ts-ignore
    handleScroll(event) {
        let scrollTop = event.path[1].pageYOffset;
        this.setState({
            scrollTop: scrollTop
        });
    }

    render() {

        return (
            <main className="homeContainer">
                <SlideShower />
                <div style={{ width: "80%", margin: "0 auto" }}>
                    <Reviews />
                    <InfoColumns />
                    <Apartments onApartmentClick={(nr: string) => {
                        this.onClickApartment(nr);
                        window.scroll(0, 0)
                    }} />
                    <Location />
                    <About />
                </div>
                <ClarisFooter />
            </main>

        );
    }

}
