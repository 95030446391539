import React, { useCallback } from 'react';
import './ApartmentImage.css';
import { useState, useEffect } from 'react';
import { Icon } from 'semantic-ui-react';

interface ApartmentImageProps {
    apartmentImages: string[];
}

var timeout: NodeJS.Timeout;
const TIMEOUT_MILLISECONDS = 4000;

const ApartmentImage = ({ apartmentImages }: ApartmentImageProps) => {

    const [imageIdx, setImageIdx] = useState(0);
    const [isMouseHovering, setIsMouseHovering] = useState(false);
    const [hasClicked, setHasClicked] = useState(false);

    const setImageAfterTimeout = useCallback(() => {
        setImageIdx(imageIdx + 1);
    }, [setImageIdx, imageIdx]);

    useEffect(() => {
        timeout = setTimeout(() => {
            if (!isMouseHovering && !hasClicked) {
                setImageAfterTimeout();
            }else {
                setHasClicked(false);
            }
        }, TIMEOUT_MILLISECONDS);
    }, [isMouseHovering, hasClicked, setHasClicked, setImageAfterTimeout]);

    return ( <div className="apartment_page_img" 
            onMouseOver={(e) => { 
                setIsMouseHovering(true);
                clearTimeout(timeout);
            }} 
            onMouseLeave={(e) => {
                setIsMouseHovering(false);
                clearTimeout(timeout);
            }}>
            {apartmentImages.map((image, idx) => {
                return (<img className={"apartment_page_actual_image"} style={idx !== imageIdx % apartmentImages.length ? {display: "none"} : {}} src={image} alt={image} />);
            })}
            <div className={"apartment_dots_container"}>{apartmentImages.map((_, idx) => {
                return (
                <div key={`apartmentImage: ${idx}`} className={idx === imageIdx % apartmentImages.length ? 
                    "apartment_page_selected_image_dot apartment_page_default_image_dot" 
                    : "apartment_page_image_dot apartment_page_default_image_dot"}
                    onClick={() => {
                        setImageIdx(idx);
                        setHasClicked(true);
                        clearTimeout(timeout);
                    }}
                    ></div>)
            })}
            </div>
            <div className={"image-navigation-container"}>
                <div className={"image-navigation-arrow"} 
                    onClick={() => {
                        if(imageIdx === 0){
                            setImageIdx(apartmentImages.length - 1);
                        }else {
                            setImageIdx(imageIdx - 1);
                        }
                        clearTimeout(timeout);
                    }}
                >
                    <Icon name="arrow left" size="large" className={"image-navigation-arrow-icon"} />
                </div>
                <div className={"image-navigation-arrow"}
                    onClick={() => {
                        setImageIdx(imageIdx + 1);
                        clearTimeout(timeout);
                    }}
                >
                    <Icon name="arrow right" size="large" className={"image-navigation-arrow-icon"} />
                </div>
            </div>
        </div>
    );
}

export { ApartmentImage }