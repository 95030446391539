import React from 'react';
import { apartmentImages } from '../apartments';

interface ApartmentGridCell {
    idx: number;
    img_idx: number;
    onApartmentClick: (idx: number) => void;
}

const ApartmentGridCell = ({ idx, img_idx, onApartmentClick }: ApartmentGridCell) => {
    return (
        <div className="apartment" onClick={() => onApartmentClick(idx)}>
            <img src={apartmentImages[idx][img_idx]} alt={apartmentImages[idx][img_idx]} />
        </div>
    );
}

export { ApartmentGridCell }