import React from 'react';

import './InfoCloumns.css'

import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import PhoneOutlinedIcon from '@material-ui/icons/PhoneOutlined';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

type InfoSectionProps = {
    icon: any,
    text: string,
}

type InfoSectionWithComponentsProps = {
    icon: any,
    textComponent1: string[],
    textComponent2: string[]
}

function SimpleTextComponent(sentences: string[]){
    return (
        <div className="simpleBreak">
            {sentences.map(prop => {
                return (<p key={prop}>{prop}</p>);
            })}
        </div>
    );
}

function InfoSection(props: InfoSectionProps){
    const listItems = props.text.split("\n").map((prop) =>
        <p>{prop}</p>
    );
    return (
        <div className="infoSectionContainer">
            <div className="infoSectionIcon">{props.icon}</div>
            <div className="simpleBreak">{listItems}</div>
        </div>
    );
}

function InfoSectionWithComponent(props: InfoSectionWithComponentsProps){
    return (
        <div className="infoSectionContainer">
            <div className="infoSectionIcon">{props.icon}</div>
            {<div>{SimpleTextComponent(props.textComponent1)}</div>}
            {<div>{SimpleTextComponent(props.textComponent2)}</div>}
        </div>
    );
}

let infoTextArray: string[] = [
    "Views of Gaudi’s famous La Pedrera, 150 meters from Passeig de Gràcia Avenue, and a 4-minute walk from Diagonal Metro Station.\nLa Rambla and Plaza Catalunya are only a 10-minute walk away.",
    "Spacious, exterior, and bright apartments. Equipped with a small kitchen with micro-wave, fridge and coffee machine. There are air condition and heating as well as free wi-fi.\n All ready for you to enjoy a relaxing and comfortable stay in Barcelona.",
    "Added Value for direct bookings on this website\nBest price guaranteed\nAdvance registration\nEarly check in / Late check out (if available)\nComplementary WiFi & Mineral Water"
]

export default function InfoColumns(){
    return (
        <section className="infoColumnsContainer">
            <InfoSection icon={<RoomOutlinedIcon style={{ fontSize: 60, color:"orange" }}/>} text={infoTextArray[0]}/>
            <InfoSection icon={<HomeOutlinedIcon style={{ fontSize: 60, color:"orange" }}/>} text={infoTextArray[1]} />
            <InfoSection icon={<AddCircleOutlineIcon style={{ fontSize: 60, color:"orange" }}/>} text={infoTextArray[2]} />
            <InfoSectionWithComponent
                icon={<PhoneOutlinedIcon style={{ fontSize: 60, color:"orange"}} />}
                textComponent1={
                    new Array<string>("(+34) 933 081 164", "info@clarisapartments.com")}
                textComponent2={
                    new Array<string>("Claris Apartments", "Pau Claris 178", "Barcelona 08037 Spain")}
            />
        </section>
    );
}
