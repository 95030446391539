import React from 'react';

import ClarisFooter from "../../common/ClarisFooter";

export default function PrivacyPage() {

    return (
        <main className={"homeContainer"}>
            <div style={{ width: "80%", margin: "0 auto" }}>
                <h2 style={{ marginTop: "20px", marginBottom: "40px" }}>PRIVACY POLICY</h2>

                <p>This Privacy Policy is applicable to the processing of personal data by Silmar Apartments S.L. (hereinafter the "Company") within its websites, app and any other electronic means or digital platform in which the company processes personal data.</p>
                <p>1.- Silmar Apartments S.L. is responsible for processing the personal data of users of this website. Please note that this data will be treated in accordance with Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of individuals with regards to the processing of personal data and on the free movement of such data.</p>
                <p>In compliance with the provisions of the Organic Law on Data Protection 15/1999, the company informs you that your personal data is incorporated into the automated files owned by the company.</p>
                
                <p>2.- Purpose of the Treatment: It is to maintain a commercial relationship with the user of this website. To carry out processing, the planned operations are:</p>
                <p>- To process orders, requests or any type of request that may be made by the user through any of the contact forms that are made available.</p>
                <p>- Sending commercial communications related to our products and services by any present or future electronic or physical means that makes it possible to carry out commercial communications.</p>
                <p>- Sending satisfaction surveys of the services provided or related to the services offered by our company. Such submissions shall be made by any electronic or physical means, present or future, that enables communications to be made....</p>
                
                <p>3.- Conservation of the data: they will be kept if there is a mutual interest in maintaining the end of the treatment. When no longer necessary for this purpose, they shall be removed with appropriate security measures to ensure their destruction.</p>
                
                <p>4.- Communication of data: The user has the right to access, rectify, delete, oppose and portability of his/her data. As well as the right to lodge a complaint with the supervisory authority, www.agpd.es, if you consider that the treatment does not comply with the regulations in force.</p>
                <p>To exercise their right, users must send their ID card or passport to:</p>
                <p>Silmar Apartments S.L., with tax identification number B65842452 and address at Carrer Pau Claris 178, porteria. 08037 Barcelona and e-mail: info@clarisapartments.com          </p>
                
                <p>5.- Obligatory nature of the information provided by the user: Users by ticking the corresponding box, expressly accept, freely and unequivocally, that their data are necessary to meet their request by the provider. The user guarantees that the personal data provided are true and is responsible for communicating any changes to them.</p>
                <p>All the data requested through the web service are obligatory, since they are necessary for the provision of the service, if not all the data are provided, there is no guarantee that the information and the service provided by the provider will be completely adapted to your needs.</p>
                
                <p>6.- Silmar Apartments S.L.  informs and expressly guarantees users that their personal data will not be transferred to third parties under any circumstances, and if any type of transfer of personal data is made, the express, informed, and unequivocal prior consent of the users will be requested.</p>
                
                <p>7.- Data of Minors: If you are a minor user, you must have the prior consent of your parents or guardians, before proceeding to include your personal data on our website. The "Company" disclaims any liability for failure to comply with this requirement.</p>
                
                <p>8.- Security Measures: In accordance with the provisions of the regulations in force regarding protection of personal data, the provider is complying with all the provisions of the regulations for the processing of personal data and the principles described in article 5 of the GDPR.</p>
                <p>It is guaranteed that appropriate technical and organizational policies have been implemented to apply the security measures established by the RGPD to protect the rights and freedoms of users and have communicated the appropriate information so that they can exercise them.</p>
                
                <p>9.- International Data Transfers: Users can communicate with the "Company" through their profiles on social networks. These profiles may be in the United States, data transfers will be covered by the agreement between the US and the European Union, Privacy Shield, or the explicit consent of the interested party, where appropriate. The aforementioned Privacy Shield agreement is a legally binding and enforceable instrument between the EU and the US, which is considered by the European Commission to provide adequate guarantees for the protection of the personal data of the interested parties.</p>
            </div>
            <ClarisFooter />
        </main >
    );
}